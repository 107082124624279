import { Accessor } from "solid-js";
import { floorCheckedNoBg, giftLandingBgTop } from "~/assets/assets";
import styles from "./gift_container.module.scss";

export function GiftBackground(props: { shadow?: Accessor<boolean> }) {
  return (
    <div class="bg-baseDark flex h-screen w-full flex-col justify-between ">
      <div
        class="flex h-[300px] w-full "
        style={`
           background-image: url(${giftLandingBgTop});
          background-size: 100% 100%;
          `}
      ></div>
      <div
        class="flex h-[300px] w-full "
        style={`
           background-image: url(${floorCheckedNoBg});
          background-size: 100% 100%;
          `}
      ></div>
      <div
        class="enter absolute bottom-[70px] left-0 right-0 flex justify-center opacity-0 transition-all duration-500"
        classList={{
          "opacity-100": props.shadow?.(),
        }}
      >
        <div
          class="bg-baseDark h-[10px] w-[10px] rounded-[500px]"
          classList={{
            [styles.shadow]: props.shadow?.(),
          }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
}

export function GiftBackgroundV3(props: { shadow?: Accessor<boolean> }) {
  return (
    <div class="bg-baseDark flex h-screen w-full flex-col justify-between ">
      <div></div>
      <div
        class="flex h-[300px] w-full "
        style={`
           background-image: url(${floorCheckedNoBg});
          background-size: 100% 100%;
          `}
      ></div>
      <div
        class="enter absolute bottom-[70px] left-0 right-0 flex justify-center opacity-0 transition-all duration-500"
        classList={{
          "opacity-100": props.shadow?.(),
        }}
      >
        <div
          class="bg-baseDark h-[10px] w-[10px] rounded-[500px]"
          classList={{
            [styles.shadow]: props.shadow?.(),
          }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
}
